var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading || _vm.localLoadingState
    ? _c("div", [
        _c(
          "div",
          {
            staticClass:
              "h-screen w-screen z-50 flex flex-row items-center justify-center",
            class: { fixed: _vm.loading }
          },
          [_vm._m(0)]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "h-screen w-screen fixed inset-0 bg-gray-900 opacity-25 z-10"
        })
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg-white p-8 rounded-lg" }, [
      _c(
        "div",
        { staticClass: "dots w-32 flex flex-row justify-between items-center" },
        [
          _c("div", { staticClass: "w-8 h-8 bg-pink-500" }),
          _vm._v(" "),
          _c("div", { staticClass: "w-8 h-8 bg-pink-500" }),
          _vm._v(" "),
          _c("div", { staticClass: "w-8 h-8 bg-pink-500" })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "bg-white flex flex-row justify-between h-12 md:px-4 shadow sticky top-0 z-50"
    },
    [
      _c(
        "a",
        {
          staticClass:
            "flex items-center text-teal-500 text-2xl font-bold md:font-normal no-underline",
          attrs: { href: _vm.url.site }
        },
        [
          _c("img", {
            staticClass: "w-full h-full block object-contain object-left",
            attrs: { src: _vm.generateUrl("logos/logo.png"), alt: "" }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "md:flex" }, [
        _c(
          "div",
          { staticClass: "flex flex-row border-l h-full" },
          [_c("message-dropdown"), _vm._v(" "), _c("profile-dropdown")],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentComponent === "timer"
    ? _c("div", [
        _c(
          "div",
          {
            staticClass:
              "fixed top-0 text-lg mx-auto mt-16 md:mt-32 max-w-lg z-40 inset-x-0"
          },
          [
            _c(
              "div",
              {
                staticClass: "flex flex-col bg-white shadow-lg relative rounded"
              },
              [
                _c("div", { staticClass: "pt-4 pr-4" }, [
                  _c(
                    "span",
                    [
                      _c("font-awesome-icon", {
                        staticClass:
                          "float-right text-grey-dark cursor-pointer",
                        attrs: { icon: _vm.faCog }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticClass:
                      "text-center text-gray-700 font-semibold text-5xl py-4"
                  },
                  [_vm._v(_vm._s(_vm.currentTime))]
                ),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "px-4 py-8" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "px-4 py-2 mx-4 rounded border border-teal-500 font-semibold text-teal-700 start",
                      attrs: { type: "button" },
                      on: { click: _vm.startCountdown }
                    },
                    [_vm._v("Start")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "px-4 py-2 mx-4 rounded border",
                      attrs: { type: "button" },
                      on: { click: _vm.pauseCountdown }
                    },
                    [_vm._v("Pause")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "px-4 py-2 mx-4 rounded border",
                      attrs: { type: "button" },
                      on: { click: _vm.resetCountdown }
                    },
                    [_vm._v("Reset")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "px-4 py-2 mx-4 rounded border",
                      attrs: { type: "button" },
                      on: { click: _vm.activateBreak }
                    },
                    [_vm._v("Break")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "border-t w-full" }),
                _vm._v(" "),
                _vm._m(1)
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "h-screen w-screen fixed inset-0 bg-gray-900 opacity-25 z-20",
          on: { click: _vm.closeTimerModal }
        })
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mx-8" }, [
      _c("div", { staticClass: "h-1 bg-gray-400 rounded" }),
      _vm._v(" "),
      _c("div", {
        staticClass: "h-1 bg-teal-500 rounded -mt-1",
        staticStyle: { width: "0%" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "px-8 py-4" }, [
      _c("span", { staticClass: "block py-2 text-gray-700" }, [
        _vm._v("Total Time : 0H 0M")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "block py-2 text-gray-700" }, [
        _vm._v("Completed Unit : 0")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.notificationShown
    ? _c(
        "div",
        {
          staticClass:
            "fixed top-0 flex flex-row justify-between items-center border-l-2 bg-white text-lg font-medium rounded container mx-auto md:w-1/2 mt-16 py-8 pl-4 pr-8 shadow-lg z-50",
          class: [
            _vm.messageType === "success"
              ? "border-green-400 text-green-800"
              : "border-red-400 text-red-800"
          ],
          staticStyle: { left: "0", right: "0", "max-width": "640px" }
        },
        [
          _c(
            "div",
            { staticClass: "flex items-center" },
            [
              _vm.messageType === "success"
                ? _c("font-awesome-icon", {
                    staticClass: "cursor-pointer text-2xl text-green-400",
                    attrs: { icon: _vm.faCheckCircle }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.messageType === "error"
                ? _c("font-awesome-icon", {
                    staticClass: "cursor-pointer text-2xl text-red-400",
                    attrs: { icon: _vm.faExclamationCircle }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "inline pl-2" }, [
                _vm._v("\n        " + _vm._s(_vm.message) + " \n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "inline",
              on: { click: _vm.closeNotificationPopup }
            },
            [
              _c("font-awesome-icon", {
                staticClass: "cursor-pointer text-sm",
                attrs: { icon: _vm.faTimes }
              })
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col" }, [
    _vm.displayDate
      ? _c(
          "div",
          {
            staticClass: "w-full flex flex-row justify-center py-2 bg-blue-100"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "text-gray-600 text-sm font-semibold text-center px-4"
              },
              [
                _vm._v(
                  "\r\n      " +
                    _vm._s(_vm.getDate(_vm.message.created_at)) +
                    "\r\n    "
                )
              ]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.message.system
      ? _c("div", { staticClass: "flex flex-row justify-center" }, [
          _c(
            "div",
            {
              staticClass:
                "bg-blue-lighter text-gray-800 text-xs text-center px-4 rounded p-2"
            },
            [_vm._v("\r\n      " + _vm._s(_vm.message.body) + "\r\n    ")]
          )
        ])
      : _c(
          "div",
          {
            staticClass: "flex flex-row text-gray-800 p-4 border-t",
            class: {
              "pb-12": _vm.last,
              "": _vm.message.user.id === _vm.user.id
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "flex flex-col items-center relative",
                class: [
                  _vm.message.user.id === _vm.user.id
                    ? "flex-col-reverse justify-end"
                    : ""
                ]
              },
              [
                _c("img", {
                  staticClass: "w-10 h-10 rounded-full",
                  class: [_vm.message.user.id === _vm.user.id ? "order-1" : ""],
                  attrs: {
                    src: _vm.generateUrl(_vm.message.user.avatar),
                    alt: _vm.message.user.name
                  }
                }),
                _vm._v(" "),
                _vm.message.user.id === _vm.user.id
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "click-outside",
                            rawName: "v-click-outside",
                            value: _vm.hideMessageMenu,
                            expression: "hideMessageMenu"
                          }
                        ],
                        staticClass: "cursor-pointer",
                        on: { click: _vm.toggleMessageMenu }
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "text-gray-500",
                          attrs: { icon: _vm.faEllipsisH }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.message.user.id === _vm.user.id && _vm.dropdownMenuShown
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "absolute rounded shadow-lg top-0 mt-16 mr-2 p-3 text-gray-800 bg-white hover:bg-indigo-200 left-0 z-10"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "cursor-pointer",
                            on: {
                              click: function($event) {
                                return _vm.deleteMessage()
                              }
                            }
                          },
                          [_vm._v("\r\n          Delete\r\n        ")]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mx-4 w-3/5 md:w-5/6" }, [
              _c("div", { staticClass: "text-xs flex flex-row px-3" }, [
                _c(
                  "div",
                  {
                    staticClass: "pr-1 font-medium",
                    class: [
                      _vm.message.user.id === _vm.user.id
                        ? "text-pink-500"
                        : "text-blue-500"
                    ]
                  },
                  [
                    _vm._v(
                      "\r\n          " +
                        _vm._s(_vm.message.user.name) +
                        "\r\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", [_vm._v("•")]),
                _vm._v(" "),
                _c("div", { staticClass: "pl-1" }, [
                  _vm._v(
                    "\r\n          " +
                      _vm._s(_vm.getTime(_vm.message.created_at)) +
                      "\r\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [{ name: "linkify", rawName: "v-linkify" }],
                  staticClass:
                    "rounded-lg p-3 pt-0 -mt-2 leading-none text-gray-800 break-words whitespace-pre-line",
                  class: [
                    _vm.message.user_id === _vm.user.id
                      ? "rounded-tr-none"
                      : "rounded-tl-none"
                  ]
                },
                [
                  _vm._v(
                    "\r\n        " + _vm._s(_vm.message.body) + "\r\n      "
                  )
                ]
              )
            ])
          ]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
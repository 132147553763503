var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentComponent === "direct-message-box"
    ? _c(
        "div",
        {
          attrs: { id: "direct-message-box" },
          on: {
            focus: function($event) {
              return _vm.clearTitleNotification()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "fixed top-0 bg-white text-lg rounded mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl mt-16 md:mt-32 shadow-lg z-40 inset-x-0"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "bg-white text-2xl text-gray-600 text-center px-8 py-4 rounded-t shadow"
                },
                [_vm._v("\r\n      Your Messages\r\n    ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-row" }, [
                _c("div", { staticClass: "bg-blue-200" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "overflow-auto overflow-y-scroll overflow-x-hidden h-50-vh w-16 lg:w-20 xxl:w-24"
                    },
                    _vm._l(_vm.users, function(user, index) {
                      return user.id !== _vm.authUser.id
                        ? _c(
                            "div",
                            {
                              staticClass: "relative p-2 lg:p-4",
                              class: {
                                "bg-white": user.id === _vm.selectedUser.id,
                                "jelly bg-white":
                                  user.unread_messages_for_auth_user_count > 0
                              },
                              on: {
                                click: function($event) {
                                  return _vm.selectUserMessage(user, index)
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "w-10 lg:w-12 h-10 lg:h-12 rounded-full cursor-pointer",
                                attrs: {
                                  title: user.name,
                                  src: _vm.generateUrl(user.avatar)
                                }
                              }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass:
                                  "absolute w-4 h-4 rounded-full border-2 border-white mb-2 mr-2 lg:mb-4 lg:mr-4 right-0 bottom-0",
                                class: [
                                  user.online ? "bg-teal-500" : "bg-gray-500"
                                ],
                                attrs: {
                                  title: [user.online ? "online" : "offline"]
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex-grow h-50-vh overflow-y-auto",
                    attrs: { id: "message-box" }
                  },
                  [
                    _vm.selectedUser.id
                      ? _c(
                          "div",
                          { staticClass: "w-full h-full" },
                          [
                            _vm.messages.length < 1
                              ? _c(
                                  "div",
                                  { staticClass: "w-full h-full" },
                                  [
                                    _c("loading-modal", {
                                      attrs: { localLoadingState: _vm.loading }
                                    }),
                                    _vm._v(" "),
                                    !_vm.loading
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex flex-col items-center justify-center"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-gray-600 text-lg text-center py-16"
                                              },
                                              [
                                                _vm._v(
                                                  '\r\n                No message yet!!! Say "Hi" to ' +
                                                    _vm._s(
                                                      _vm.selectedUser.name
                                                    ) +
                                                    "\r\n              "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("img", {
                                              staticClass: "w-96",
                                              attrs: {
                                                src: "/image/dm.svg",
                                                alt: "direct message"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.messages, function(message, index) {
                              return _c("message", {
                                key: index,
                                attrs: {
                                  message: message,
                                  user: _vm.authUser,
                                  index: index,
                                  last: _vm.messages.length === index + 1,
                                  direct: true
                                },
                                on: { deleted: _vm.deleteMessage }
                              })
                            })
                          ],
                          2
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-col items-center justify-center"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-gray-600 text-lg text-center py-16"
                              },
                              [
                                _vm._v(
                                  "\r\n            Click on the profile pic on left to see interaction with that user\r\n          "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "w-64",
                              attrs: {
                                src: "/image/select.svg",
                                alt: "direct message"
                              }
                            })
                          ]
                        )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "relative bg-gray-200" }, [
                _c("div", { staticClass: "static text-center p-4" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.message,
                        expression: "message"
                      }
                    ],
                    ref: "messageTextarea",
                    staticClass:
                      "static textarea resize-none rounded w-full p-4 text-gray-800",
                    style: { height: _vm.messageTextareaHeight },
                    attrs: {
                      id: "send-message",
                      placeholder: _vm._f("localize")(
                        "write your message here"
                      ),
                      rows: "1",
                      disabled: _vm.isDisabled
                    },
                    domProps: { value: _vm.message },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        $event.preventDefault()
                        return _vm.sendMessage($event)
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.message = $event.target.value
                      }
                    }
                  })
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "h-screen w-screen fixed inset-0 bg-gray-900 opacity-25 z-20",
            on: { click: _vm.hideMessageBox }
          })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }